<template>
  <div class="prospect-custom-wrapper">
    <el-table
      class="table-condensed"
      :data="prospects"
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      :cell-style="{ padding: '0', height: '100px' }"
      @row-click="clickHandler"
      @sort-change="sortChangeHandler"
    >
      <el-table-column label="Ai" width="50" prop="prospect.urssafInscription">
        <template slot-scope="scope" v-if="scope.row.urssafInscription">
          <template
            v-if="
              !scope.row.urssafInscription.idClient &&
              scope.row.urssafInscription.status === 'pending'
            "
          >
            <i class="fa fa-balance-scale text-danger" aria-hidden="true"></i>
          </template>
          <template v-else-if="scope.row.urssafInscription.idClient">
            <i class="fa fa-balance-scale text-success" aria-hidden="true"></i>
          </template>
          <template
            v-else-if="
              !scope.row.urssafInscription.idClient &&
              scope.row.urssafInscription.status === 'send'
            "
          >
            <i class="fa fa-balance-scale text-warning" aria-hidden="true"></i>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="Lead web" width="60">
        <template slot-scope="scope" v-if="scope.row.prospect.lastFormFilledAt">
          <i class="fa fa-wpforms text-primary" aria-hidden="true"></i>
        </template>
      </el-table-column>

      <el-table-column
        label="Statut"
        width="100"
        sortable="custom"
        prop="prospect.type"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.prospect && scope.row.prospect.type">
            <span
              v-if="scope.row.prospect.type === 'converti'"
              class="d-block bg-success text-white"
              style="padding: 5px; text-align: center"
              >Converti</span
            >
            <span
              v-if="scope.row.prospect.type === 'treated'"
              class="d-block bg-info text-white"
              style="padding: 5px; text-align: center"
              >Traité</span
            >
            <span
              v-if="scope.row.prospect.type === 'perdu'"
              class="d-block bg-danger text-white"
              style="padding: 5px; text-align: center"
              >Perdu</span
            >
            <span
              v-if="scope.row.prospect.type === 'en-attente'"
              class="d-block bg-warning text-white"
              style="padding: 5px; text-align: center"
              >En attente</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Prénom"
        width="100"
        sortable="custom"
        prop="firstName"
      ></el-table-column>
      <el-table-column
        label="Nom"
        width="100"
        sortable="custom"
        prop="lastName"
      ></el-table-column>
      <el-table-column label="Fiche" sortable="custom" width="80" prop="email">
        <template slot-scope="scope">
          <span @click.stop="goToClient(scope.row)" class="user-link">
            <i class="icon-user"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="CP"
        width="80"
        sortable="custom"
        prop="zipcode"
      ></el-table-column>
      <el-table-column
        label="Ville"
        width="120"
        sortable="custom"
        prop="city"
      ></el-table-column>
      <el-table-column
        label="Coachs secteur"
        width="160"
        prop="nbPrestatairesAround"
      >
      </el-table-column>
      <el-table-column label="Commerciaux" min-width="280">
        <template slot-scope="scope">
          <div
            class="block-ctn"
            v-if="scope.row.commercials && scope.row.commercials.length > 0"
          >
            <div class="commercials-ctn">
              <div
                v-for="commercial in scope.row.commercials"
                :key="
                  commercial.commercial
                    ? commercial.commercial.commercial.pseudo
                    : commercial.id
                "
                class="text-white rounded-3 text-nowrap"
                :style="
                  commercial.commercial &&
                  commercial.commercial.commercial.color &&
                  `background-color:${commercial.commercial.commercial.color}`
                "
                :class="
                  commercial.commercial &&
                  !commercial.commercial.commercial.color &&
                  'bg-primary'
                "
              >
                {{
                  commercial.commercial &&
                  commercial.commercial.commercial.pseudo
                }}
              </div>
            </div>
            <button
              class="btn btn-sm my-1"
              @click.stop="editCommercials(scope.row)"
              v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
            >
              <i class="icon-pencil"></i>
            </button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Créé le"
        sortable="custom"
        prop="createdAt"
        width="100"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.prospectSince) }}
        </template>
      </el-table-column>
      <el-table-column width="225" label="Note">
        <template slot-scope="scope">
          <NoteModal
            style="margin-top: 1px"
            :notes="scope.row.prospect.note"
            :key="scope.row.id"
            v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
            @showComment="showComment(scope.row)"
          />
          <span v-else>
            {{ scope.row.prospect.note }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="B2B" width="150">
        <template slot-scope="scope">
          <div class="d-flex flex-column align-items-center">
            {{ scope.row.isB2B ? "Oui" : "Non" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Changer type"
        width="120"
        v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.prospect.type === 'converti'">
            <span
              @click.stop="changeProspectType(scope.row, 'en-attente')"
              class="bg-warning mr-1 action-btn"
              >A</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'treated')"
              class="bg-info mr-1 action-btn"
              >T</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'perdu')"
              class="bg-danger action-btn"
              >X</span
            >
          </div>
          <div v-if="scope.row.prospect.type === 'treated'">
            <span
              @click.stop="changeProspectType(scope.row, 'en-attente')"
              class="bg-warning mr-1 action-btn"
              >A</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'converti')"
              class="bg-success mr-1 action-btn"
              >C</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'perdu')"
              class="bg-danger action-btn"
              >X</span
            >
          </div>
          <div v-if="scope.row.prospect.type === 'perdu'">
            <span
              @click.stop="changeProspectType(scope.row, 'en-attente')"
              class="bg-warning mr-1 action-btn"
              >A</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'treated')"
              class="bg-info mr-1 action-btn"
              >T</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'converti')"
              class="bg-success action-btn"
              >C</span
            >
          </div>
          <div v-if="scope.row.prospect.type === 'en-attente'">
            <span
              @click.stop="changeProspectType(scope.row, 'treated')"
              class="bg-info mr-1 action-btn"
              >T</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'converti')"
              class="bg-success mr-1 action-btn"
              >C</span
            >
            <span
              @click.stop="changeProspectType(scope.row, 'perdu')"
              class="bg-danger action-btn"
              >X</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Archives"
        width="120"
        v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
      >
        <template slot-scope="scope">
          <button
            v-if="!scope.row.archived"
            class="btn btn-info"
            @click.stop="$emit('archiveProspect', scope.row)"
          >
            <i class="icon icon-briefcase"></i>
          </button>
          <div v-else class="alert alert-info m-0 text-center">Archivé</div>
        </template>
      </el-table-column>
      <div slot="empty">
        Cliquer sur le bouton "Visualiser" pour afficher les prospects.
      </div>
    </el-table>

    <paginate
      v-model="page"
      ref="paginate"
      :page-count="count"
      :click-handler="paginationHandler"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
      class="pt-1"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import NoteModal from "./form/NoteModal";

export default {
  components: {
    Paginate,
    NoteModal,
  },

  props: {
    prospects: {
      type: Array,
      default: null,
    },
    prospectsTotal: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
  },

  computed: {
    count() {
      return Math.ceil(this.prospectsTotal / this.rows);
    },
  },

  watch: {
    page() {
      this.$refs.paginate.selected = this.page - 1;
    },
  },

  methods: {
    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    showComment(prospect) {
      this.$emit("showComment", prospect);
    },

    editCommercials(prospect) {
      this.$emit("editCommercials", prospect);
    },

    goToClient(prospect) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id: prospect.id,
        },
      });
      window.open(route.href, "_blank");
    },

    changeProspectType(prospect, type) {
      this.$emit("changeProspectType", { prospect, type });
    },

    clickHandler(row) {
      this.$router.push({
        name: "client-details",
        params: {
          id: row.id,
        },
      });
    },

    sortChangeHandler(sortBy) {
      this.$emit("sortChange", sortBy);
    },
  },
};
</script>

<style lang="scss">
/*[TODO] May we improved */
.prospect-custom-wrapper {
  max-width: calc(100vw - 450px);
}

@media (max-width: 991px) {
  .prospect-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .prospect-custom-wrapper {
  max-width: calc(100vw - 70px);
}

.user-link {
  color: #20a8d8;
  cursor: pointer;
}

.commercials-ctn,
.block-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.commercials-ctn {
  padding: 0 5px;
  overflow: auto;

  div {
    margin-right: 5px;
    display: inline-block;
    padding: 0 5px;
    max-height: 28px;
  }
}

.action-btn {
  padding: 5px;
  cursor: pointer;
}
</style>
