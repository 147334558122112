<template>
  <div class="row">
    <div class="col-sm-7">
      <ClientAttributionCard :client="client" v-if="!client.deletedAt" />

      <ClientGlobalCard
        :client="client"
        :orders="client.orders"
        :sessions="client.sessions"
        :concepts="concepts"
        :groups="groups"
        @edit="edit"
      />

      <ClientUrssafCard :client="client" v-if="!client.deletedAt" />
    </div>
    <div class="col-sm-5">
      <ClientStatusCard
        :client="client"
        :orders="client.orders"
        :disabled-dates="disabledDates"
        @edit="edit"
        @reload="reload"
      />

      <ClientCommercialCard
        :client="client"
        :commercials="commercials"
        :disabled-dates="disabledDates"
        @edit-notes="editNotes"
      />

      <ClientCompanyCard :client="client" v-if="!client.deletedAt" />

      <ClientGeolocCard :client="client" v-if="!client.deletedAt" />

      <ClientGoalsCard :clientData="client" @edit="edit" />

      <ClientInfoAdminCard :client="client" @edit="edit" />
    </div>
  </div>
</template>

<script>
import ClientGlobalCard from "../components/ClientGlobalCard";
import ClientStatusCard from "../components/ClientStatusCard";
import ClientAttributionCard from "../components/ClientAttributionCard";
import ClientCommercialCard from "../components/ClientCommercialCard";
import ClientGeolocCard from "../components/ClientGeolocCard";
import ClientGoalsCard from "../components/ClientGoalsCard";
import ClientUrssafCard from "../components/ClientUrssafCard";
import ClientCompanyCard from "../components/ClientCompanyCard";
import ClientInfoAdminCard from "../components/ClientInfoAdminCard";

export default {
  components: {
    ClientGlobalCard,
    ClientStatusCard,
    ClientAttributionCard,
    ClientCommercialCard,
    ClientGeolocCard,
    ClientGoalsCard,
    ClientUrssafCard,
    ClientCompanyCard,
    ClientInfoAdminCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    groups: {
      type: Array,
      default: () => [],
    },

    concepts: {
      type: Array,
      default: () => [],
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    disabledDates: {
      type: Object,
      required: true,
    },
  },

  methods: {
    edit({ name, value }) {
      this.$emit("edit", { name, value });
    },

    editNotes() {
      this.$emit("editNotes");
    },

    reload() {
      this.$emit("reload");
    },
  },
};
</script>
